import React, { useEffect } from "react"
import gsap from "gsap"
import { clearAllBodyScrollLocks } from "body-scroll-lock"

const SubHero = ({ title, type }) => {
  useEffect(() => {
    clearAllBodyScrollLocks()
    gsap.timeline().from(".subHero h1", {
      y: 40,
      opacity: 0,
      duration: 0.75,
    })
  }, [])

  let HeaderClass = title ? "subHero" : "subHero pseudoNav"
  if (type === "campaigns") HeaderClass += " campaigns-bg"
  else HeaderClass += " work-bg"

  return <header className={HeaderClass}>{title && <h1>{title}</h1>}</header>
}

export default SubHero
