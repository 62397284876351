import React from "react"
import Layout from "../../components/Layout"
import SubHero from "../../components/SubHero"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Seo from "../../components/Seo"

export const query = graphql`
  query BlogPostTwo {
    file(relativeDirectory: { eq: "blog" }, name: { eq: "blog2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const BlogPageTwo = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="To New Beginnings…"
        description=" The two most important parts of any journey are its ending
           and its beginning. Beginnings give you something to look forward to. It makes you
           curious and aspirational."
      />
      <SubHero title={null} />
      <section className="blogpost__container">
        <div className="blogpost__meta">
          <h1>To New Beginnings…</h1>
          <p>
            The two most important parts of any journey are its <b>ending</b>{" "}
            &amp; its <b>beginning.</b>
          </p>
        </div>
        <div className="post">
          <Img fluid={data.file.childImageSharp.fluid} alt="" />
          <p>
            <br />
            Endings make you look back and reflect upon everything that
            happened. It makes you grateful, and humble.
            <br />
            <br />
            Beginnings give you something to look forward to. It makes you
            curious and aspirational.
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            While one allows you to reflect on past mistakes, the other gives
            you a chance to make new ones.
          </p>
          <p>
            Officially we have one of each every year — December 31 &amp;
            January 1.
            <br /> <br />
            We are already one month into 2020 and January felt like it flew.
            Just like that. In the blink of an eye.
          </p>
          <p className="quote" style={{ lineHeight: 1.325 }}>
            How many of us are still “planning” to read that book? <br />
            How many of us are still “thinking” to start that thing? <br />
            How many of us are still “waiting for permissions”? <br />
          </p>
          <p>
            <b>
              How many of us are still guilty of answering the above questions?
              Well, tbh — I am.
            </b>
            <br /> <br />
            And if your response to one of these questions is a speechless face
            (with a tinge of guilt) or an outright — YES, it’s alright. It is
            okay. I understand that it has been difficult. <br />
            <br /> <b>UNTIL NOW.</b>
            <br />
            Following up in the next article. <br />
            <br />
            Love.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPageTwo
